import {
  Component,
  OnInit,
  Input,
  HostListener,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { Product } from "../../classes/product";
import { ProductService } from "../../services/product.service";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { NavigationExtras, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-header-two",
  templateUrl: "./header-two.component.html",
  styleUrls: ["./header-two.component.scss"],
})
export class HeaderTwoComponent implements OnInit {
  public products: Product[] = [];
  isMobile: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private localize: LocalizeRouterService,
    private router: Router,
    private http: HttpClient
  ) {
    let translatedPath: any = this.localize.translateRoute("/search");
    if (this.router.url.split("?")[0] == translatedPath) {
      this.isSearchPage = true;
    } else {
      this.isSearchPage = false;
    }
  }

  ngOnInit(): void {
    this.updateScreenVariable();
  }

  updateScreenVariable() {
    this.isSmallScreen = window.innerWidth < 768;

    this.isMobile = window.innerWidth < 1200;
  }

  timer;
  time = 500;

  public isSmallScreen = window.innerWidth < 768;

  @HostListener("window:resize", ["$event"])
  onWindowResize(event?) {
    this.updateScreenVariable();
  }

  SearchModel: any = {};
  Searching;
  IsLoadingSearch;
  SearchProducts: any = [];
  isSearchPage = false;

  OpenSearchModal() {
    if (!this.isSearchPage) {
      if (this.IsSearching) {
        this.IsSearching = false;
        this.click({ IsSearching: false });
      } else {
        this.IsSearching = true;
        this.click({ IsSearching: true });
      }
    } else {
      this.IsSearching = false;
    }
  }

  gotoSearch() {
    const navigatioExtra: NavigationExtras = {
      queryParams: { q: this.SearchModel.SearchText },
    };
    let translatedPath: any = this.localize.translateRoute("/search");
    this.router.navigate([translatedPath], navigatioExtra);
  }
  ClearSearch() {
    this.SearchProducts = [];
    this.SearchModel.SearchText = null;
    this.Searching = false;
  }
  searchBar() {
    if (this.SearchModel.SearchText.length >= 3) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.Searching = true;
        this.IsLoadingSearch = true;
        this.http
          .get<any>(
            environment.url +
              "Products/Search?searchKeys=" +
              this.SearchModel.SearchText +
              "&langCode=" +
              this.translate.currentLang
          )
          .subscribe((data) => {
            this.SearchProducts = data;
            this.IsLoadingSearch = false;
          });
      }, this.time);
    } else {
      this.Searching = false;
    }
  }
  CloseTheSearch() {
    this.IsSearching = false;
    this.click({ IsSearching: false });
  }

  public currencies = [
    {
      name: "Euro",
      currency: "EUR",
      price: 0.9, // price of euro
    },
    {
      name: "Rupees",
      currency: "INR",
      price: 70.93, // price of inr
    },
    {
      name: "Pound",
      currency: "GBP",
      price: 0.78, // price of euro
    },
    {
      name: "Dollar",
      currency: "USD",
      price: 1, // price of usd
    },
  ];

  IsSearching;
  @Input() home: boolean = false;
  @Input() themeLogo: string = "assets/images/icon/mibebito_logo_black.png"; // Default Logo
  @Input() responsivethemeLogo: string =
    "assets/images/icon/mibebito_logo_black.png"; // Default Logo
  @Input() responsivethemeLogodark: string =
    "assets/images/icon/mibebito_logo_black.png"; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() stick: boolean = false; // Default false
  alerts = true;
  backgroundwhite: boolean = false;

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }
  click(data) {
    if (data.IsSearching) this.IsSearching = true;
    else this.IsSearching = false;
  }
  CloseAlert() {
    this.alerts = false;
  }
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 10 && window.innerWidth > 200) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
}
