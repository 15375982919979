import { Component, Injectable, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  home = false;

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private http: HttpClient,private titleService: Title, private metaService: Meta,
    private loader: LoadingBarService,private translate: TranslateService, private localize: LocalizeRouterService) {
      
    this.GetMainSeo();
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
    }
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.split('/').length > 2) {
          this.GetMainSeo();
          this.home = false;
        }
        else {
          this.GetMainSeo();
          this.home = true;
        }
      }
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    if (this.router.url.split('/').length > 2) {
      this.home = false;
    }
    else {
      this.home = true;
    }
  }
  GetMainSeo(){
    this.http.get<any>(environment.url + 'MainPageSeo/GetMainPageSeoByLangCode?LangCode=' + this.translate.currentLang).subscribe(data => {
      this.titleService.setTitle(data.Data[0].MetaTitle);
      this.metaService.removeTag("name='description'");
      this.metaService.removeTag("name='keywords'");
      this.metaService.addTags([
        {name: 'keywords', content: data.Data[0].MetaKeywords},
        {name: 'description', content: data.Data[0].MetaDescription}
      ]);
    });
  }
}
