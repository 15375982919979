<!-- header start -->
<header
  id="header"
  [ngClass]="{
    sticky: home && !stick,
    fixed: (stick && sticky) || IsSearching,
    backgroundwhite: backgroundwhite || IsSearching,
    'home-fixed': (stick && sticky && home) || IsSearching
  }"
  (mouseover)="backgroundwhite = true"
  (mouseout)="backgroundwhite = false"
>
  <!--   <div
    class="alert alert-top alert-dismissible fade"
    [ngClass]="{ show: alerts, ' d-none': !alerts }"
    role="alert"
  >
    <a href="{{ 'header:top-alert-link' | translate }}" style="color: #fff">
      {{ "header:top-alert" | translate }}
    </a>
    <button
      type="button"
      style="
        margin-right: 10px;
        margin-top: 0px;
        padding: 3px;
        font-weight: 400;
      "
      (click)="CloseAlert()"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button> 
  </div>-->
  <!-- <div class="top-header top-header-dark2" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li>Welcome to Our store MIBEBITO</li>
                            <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-right">
                    <ul class="header-dropdown">
                        <li class="compare">
                            <a [routerLink]="['/shop/compare']" style="color: white;"><i class="fa fa-random"
                                    aria-hidden="true"></i>Compare</a>
                        </li>
                        <li class="mobile-wishlist">
                            <a [routerLink]="['/shop/wishlist']" style="color: white;"><i class="fa fa-heart"
                                    aria-hidden="true"></i>Wishlist</a>
                        </li>
                        <li class="onhover-dropdown mobile-setting" style="margin-right: 10px;">
                            <i class="ti-world" style="font-size: 16px;"></i>
                            <ul class="onhover-show-div">
                                <li *ngFor="let language of languages">
                                    <a href="javascript:void(0)" style="color: black!important;"
                                        (click)="changeLanguage(language.code)">{{ language.name }}</a>
                                </li>
                            </ul>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->

  <div class="container layout3-menu">
    <div class="row">
      <div
        class="col-lg-12 padding-50"
        [ngClass]="{ 'border-bottom-responsive': stick }"
      >
        <div
          class="main-menu"
          [ngStyle]="
            isMobile
              ? { 'justify-content': 'space-between' }
              : { 'justify-content': 'end', height: '80px' }
          "
        >
          <div class="menu-left around-border" *ngIf="isMobile">
            <div class="main-menu-right">
              <app-menu
                [stick]="stick"
                [home]="home"
                [IsSearching]="IsSearching"
              ></app-menu>
            </div>
          </div>
          <div *ngIf="!isSmallScreen" class="absolute-logo">
            <div class="brand-logo">
              <a [routerLink]="['/' | localize]">
                <img
                  class="display-logo"
                  style="vertical-align: baseline; width: 275px"
                  [src]="themeLogo"
                  alt="logo"
                />
                <img
                  *ngIf="!stick && home && !IsSearching"
                  class="responsive-logo"
                  style="vertical-align: baseline"
                  [src]="responsivethemeLogo"
                  alt="logo"
                />
                <img
                  *ngIf="stick || !home || IsSearching"
                  class="responsive-logo"
                  style="vertical-align: baseline"
                  [src]="responsivethemeLogodark"
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div class="sx" [ngStyle]="{ 'width.%': isSmallScreen ? 100 : null }">
            <div
              class="menu-right pull-right"
              [ngStyle]="{ 'width.%': isSmallScreen ? 100 : null }"
            >
              <div
                [ngStyle]="{
                  'width.%': isSmallScreen ? 100 : null,
                  'justify-content': 'end'
                }"
              >
                <app-settings
                  [sticky]="stick"
                  (myevent)="click($event)"
                  [home]="home"
                  [themeLogo]="
                    !stick && home && !IsSearching
                      ? responsivethemeLogo
                      : responsivethemeLogodark
                  "
                ></app-settings>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-left around-border" *ngIf="!isMobile">
          <div class="main-menu-right" style="margin-left: 0.2rem">
            <app-menu
              [stick]="stick"
              [home]="home"
              [IsSearching]="IsSearching"
            ></app-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->
