import { UserModel } from '../models/user.model';
import jwt_decode from 'jwt-decode';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor(private router: Router,@Inject(PLATFORM_ID) private platformId: Object,) { }

    public getUser(): UserModel {
        
        if (isPlatformBrowser(this.platformId)) {
            let token;
            if (localStorage.getItem("tokenVIP")) {
                token = localStorage.getItem("tokenVIP");
            }

            if (token == null) {
                return null
            }
            let jwtToken;
            try{
                if(jwt_decode(token)){
                    jwtToken = jwt_decode(token);
                }
                else{
                    return null
                }
    
                return <UserModel>JSON.parse(JSON.stringify(jwtToken));
            }
            catch(Error){
                return null;
            }
           
        }
    }


    public jwtToUser(token: any): UserModel {
        let jwtToken = jwt_decode(token);
        return <UserModel>JSON.parse(JSON.stringify(jwtToken));
    }

}