<ngx-loading-bar></ngx-loading-bar>
<app-header-two [stick]="false" [home]="home" [sticky]="true"  [topbar]="false" ></app-header-two>
<router-outlet></router-outlet>
<app-footer-one></app-footer-one>
<app-tap-to-top></app-tap-to-top>

<app-accept-cookies></app-accept-cookies>

<app-newsletter></app-newsletter>
<a href="https://wa.me/%2B905394740505?text={{'whatsapp-chat-message' | translate}}" target="_blank" style="position: fixed;z-index: 9999999;bottom: 30px;right: 17px;">
    <img src="assets/images/whatsapp.png" style="width: 55px;" />
</a>