<app-breadcrumb [breadcrumb]="data.PageName"></app-breadcrumb>

<section class=" contact-page section-b-space padding-50" *ngIf="data.InnerHTML">
    <div class="row mb-4" *ngIf="WideBanner">
        <div class="col-md-12" *ngIf="WideBanner.URL && WideBanner.IsActive">
            <a [href]="WideBanner.URL | localize">
                <img style="width: 100%;" *ngIf="WideBanner.IsImage"
                    [ngStyle]="{'border-radius': WideBanner.BorderRadius}" [alt]="WideBanner.Alt"
                    src="https://scm.mibebito.com/AdminService/{{WideBanner.ImageLocation}}" />
                <video *ngIf="WideBanner.IsVideo" [ngStyle]="{'border-radius': WideBanner.BorderRadius}"
                    style="width: 100%;" loop muted autoplay oncanplay="this.play()"
                    onloadedmetadata="this.muted = true">
                    <source src="https://scm.mibebito.com/AdminService/{{WideBanner.ImageLocation}}"
                        type="video/mp4" />
                </video>
                <div
                    style="display: flex; flex-direction: column; align-items: stretch; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;">
                    <div class="footer-banner-big" [ngStyle]="{'margin': WideBanner.DivMargin}">
                        <div [style]="WideBanner.DivText">
                            <span class="banner-text" *ngIf="WideBanner.TextArea"
                                [innerHTML]="WideBanner.TextArea | sanitizeHtml"></span>
                        </div>
                    </div>
                    <div *ngIf="WideBanner.DivButton" [innerHTML]="WideBanner.DivButton | sanitizeHtml">

                    </div>
                </div>
            </a>
        </div>
        <div class="col-md-12" *ngIf="!WideBanner.URL && WideBanner.IsActive">
            <img style="width: 100%;" *ngIf="WideBanner.IsImage" [ngStyle]="{'border-radius': WideBanner.BorderRadius}"
                [alt]="WideBanner.Alt" src="https://scm.mibebito.com/AdminService/{{WideBanner.ImageLocation}}" />
            <video *ngIf="WideBanner.IsVideo" [ngStyle]="{'border-radius': WideBanner.BorderRadius}"
                style="width: 100%;" loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                <source src="https://scm.mibebito.com/AdminService/{{WideBanner.ImageLocation}}" type="video/mp4" />
            </video>
            <div
                style="display: flex; flex-direction: column; align-items: stretch; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;">
                <div class="footer-banner-big" [ngStyle]="{'margin': WideBanner.DivMargin}">
                    <div [style]="WideBanner.DivText">
                        <span class="banner-text" *ngIf="WideBanner.TextArea"
                            [innerHTML]="WideBanner.TextArea | sanitizeHtml"></span>
                    </div>
                </div>
                <div *ngIf="WideBanner.DivButton" [innerHTML]="WideBanner.DivButton | sanitizeHtml">

                </div>
            </div>
        </div>

    </div>
    <div class="row mb-3" *ngIf="data.IsContactForm">
        <div class="col-lg-12" style="text-align: right;">
            <button class="btn btn-contact" type="button" (click)="gotoContact()">{{'cms:-goto-contact-send-button' |
                translate}}</button>
        </div>
    </div>
    <div [innerHTML]="data.InnerHTML | sanitizeHtml"></div>
    <div class="row mb-3 mt-3" *ngIf="data.IsContactForm" id="contact-form" style="justify-content: center;">
        <div class="col-sm-8 mt-3">
            <h3 style="color: black;font-weight: 400;">{{'apply:contact-form-title' | translate}}
            </h3>
            <span style="margin-top: 15px;">{{'apply:contact-form-text' | translate}}</span>
            <form class="theme-form mt-4" #contactform="ngForm"
                (ngSubmit)="contactform.form.valid && SendMail(contactform)">
                <div class="form-row">
                    <div class="col-md-6 mb-3">
                        <label for="name">{{'apply:contact-first-name' | translate}}</label>

                        <input type="text" name="FirstName" style="font-size: 13px;" #FirstName="ngModel"
                            class="form-control" autocomplete="nope" id="FirstName"
                            pattern="^[a-zA-Z0-9ğüışöçİĞÜŞÖÇ^>{}\/|;:.,~!?@#$%^=&'_()+* ]+$"
                            placeholder="{{'apply:contact-first-name-placeholder' | translate}}"
                            [(ngModel)]="ContactModel.FirstName"
                            [ngClass]="{ 'is-invalid': contactform.submitted && FirstName.invalid}" required>
                        <div *ngIf=" contactform.submitted && FirstName.invalid" class="invalid-feedback">
                            <div>{{'apply:contact-firstname-validation' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="email">{{'apply:contact-last-name' | translate}}</label>

                        <input type="text" name="LastName" style="font-size: 13px;" #LastName="ngModel"
                            class="form-control" autocomplete="nope" id="LastName"
                            pattern="^[a-zA-Z0-9ğüışöçİĞÜŞÖÇ^>{}\/|;:.,~!?@#$%^=&'_()+* ]+$"
                            placeholder="{{'apply:contact-last-name-placeholder' | translate}}"
                            [(ngModel)]="ContactModel.LastName"
                            [ngClass]="{ 'is-invalid': contactform.submitted && LastName.invalid}" required>
                        <div *ngIf=" contactform.submitted && FirstName.invalid" class="invalid-feedback">
                            <div>{{'apply:contact-lastname-validation' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="review">{{'apply:contact-number' | translate}}</label>

                        <ngx-intl-tel-input [ngClass]="{ 'is-invalid': contactform.submitted && PhoneNumber.invalid}"
                            [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [phoneValidation]="false"
                            searchCountryPlaceholder="{{'searchCountry:searchCountry-title' | translate }}"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Turkey" [maxLength]="15"
                            [phoneValidation]="true" [separateDialCode]="separateDialCode"
                            [numberFormat]="PhoneNumberFormat.National" name="phone" id="PhoneNumber"
                            [(ngModel)]="ContactModel.PhoneNumber" #PhoneNumber="ngModel" required>
                        </ngx-intl-tel-input>
                        <div *ngIf=" contactform.submitted && PhoneNumber.invalid" class="invalid-feedback">
                            <div>{{'apply:contact-phone-validation' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="email">{{'apply:contact-email' | translate}}</label>
                        <input type="email" name="Email" style="font-size: 13px;" #Email="ngModel" class="form-control"
                            autocomplete="nope" id="Email" [email]="true"
                            placeholder="{{'apply:contact-email-placeholder' | translate}}"
                            [(ngModel)]="ContactModel.Email"
                            [ngClass]="{ 'is-invalid': contactform.submitted && Email.invalid}" required
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                        <div *ngIf=" contactform.submitted && Email.invalid" class="invalid-feedback">
                            <div>{{'apply:contact-email-validation' | translate}}</div>
                        </div>

                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="name">{{'apply:contact-subject' | translate}}</label>

                        <input type="text" name="Subject" style="font-size: 13px;" #Subject="ngModel"
                            class="form-control" autocomplete="nope" id="Subject"
                            placeholder="{{'apply:contact-subject-placeholder' | translate}}"
                            [(ngModel)]="ContactModel.Subject"
                            [ngClass]="{ 'is-invalid': contactform.submitted && Subject.invalid}" required>
                        <div *ngIf=" contactform.submitted && Subject.invalid" class="invalid-feedback">
                            <div>{{'apply:contact-subject-validation' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="review">{{'apply:contact-message' | translate}}</label>
                        <textarea class="form-control" name="Message" #Message="ngModel"
                            placeholder="{{'apply:contact-message-placeholder' | translate}}"
                            [ngClass]="{ 'is-invalid': contactform.submitted && Message.invalid}"
                            [(ngModel)]="ContactModel.Message" id="exampleFormControlTextarea1" rows="6"
                            required></textarea>
                        <div *ngIf=" contactform.submitted && Message.invalid" class="invalid-feedback">
                            <div>{{'apply:contact-message-validation' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button class="btn btn-solid" type="submit"
                            [disabled]="isSendingMail">{{'apply:contact-send-button'
                            | translate}}<span class="spinner-border spinner-border-sm" *ngIf="isSendingMail"
                                style="margin-left: 10px;margin-bottom: 1px;"></span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>