<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar" [ngClass]="{'responsive-white': false && !stick && home && !IsSearching}"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle" [@slideInOut]>

      <li class="back-btn">
        <div class="row" style="margin: 0;">
          <div class="col-6" style="display: flex;align-items: center;padding: 0;">
            <img class="responsive-logo mobile-back" style="vertical-align: baseline;" [src]="responsivethemeLogodark"
              alt="logo">

          </div>
          <div class="col-6" style="padding: 0;">
            <div class="mobile-back text-right" (click)="mainMenuToggle()">
              <img src="assets/images/cancel.svg" style="width: 12px;" alt="">
            </div>
          </div>
        </div>

      </li>

      <li class="responsive-menu-item category-item" style="height: 120px;display: flex;align-items: center;"
        *ngFor="let item of Categories"><a [href]="[item.RowLink] | localize" style="font-weight: 500;">
          {{item.MenuTag}} </a><img style="width: 80px; border-radius: 3px;position: absolute;right: 20px;top: 0;"
          *ngIf="item.ImageLocation" src="https://scm.mibebito.com/AdminService/{{item.ImageLocation}}"></li>



      <ng-container *ngFor="let menuItem of MenuListByLanguage;let last = last;">
        <li [class.mega-menu]="menuItem.IsActive">

          <!-- Sub -->
          <a href="javascript:void(0)" class="nav-link" [style.color]="menuItem.FontColor"
            [style.font-weight]="menuItem.FontStyle == 'Bold' ? 500 : 400"
            [style.font-size]="getMenuFontSize()"
            *ngIf="menuItem.MenuTypeStr === '#' && !isRowMenuOpen && !isColumnMenuOpen">
            {{ menuItem.MenuTag | translate }}
          </a>
          <!-- Link -->
          <a [href]="[menuItem.MenuLink] | localize" target="{{menuItem.OpenType == '_blank' ? '_blank' : '_self'}}"
            [style.color]="menuItem.FontColor" class="nav-link"
            [style.font-size]="getMenuFontSize()"
            [style.font-weight]="menuItem.FontStyle == 'Bold' ? 500 : 400"
            *ngIf="menuItem.MenuTypeStr != '#' && !isRowMenuOpen && !isColumnMenuOpen">
            {{ menuItem.MenuTag }}
          </a>
          <span class="sub-arrow mobile-back" *ngIf="menuItem.Columns.length && !isRowMenuOpen && !isColumnMenuOpen"
            (click)="OpenColumnNavActive(menuItem)"></span>

          <!-- <a [href]="menuItem.MenuLink" [style.color]="menuItem.FontColor" class="nav-link sub-arrow"
            *ngIf="menuItem.MenuTypeStr === 'Link'">
            {{ menuItem.MenuTag }}
            <span class="sub-arrow mobile-back" *ngIf="menuItem.Columns"></span>
          </a> -->
          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [@slideInOut] style="overflow-y: auto;height: calc(100vh - 100px);"
            [class.opensubmenu]="menuItem.Ref == ActiveItem.Ref"
            *ngIf="ColumnMenuItems.length && !isRowMenuOpen && isColumnMenuOpen">


            <li class="back-btn first-back-sticky">
              <div class="row" style="margin: 0;">
                <div class="col-12" style="display: flex;align-items: center;padding: 0;">
                  <a (click)="backToMainMenu()" style="padding: 0;">
                    <div class="mobile-back" style="padding: 10px;font-size: 15px;">
                      <img alt="" src="assets/images/icon/left-arrow.svg"
                        style="width: 30px;margin-right: 25px;">{{ActiveItem.MenuTag}}
                    </div>
                  </a>
                </div>
              </div>
            </li>


            <li *ngFor="let childrenItem of ColumnMenuItems">
              <!-- Sub -->
              <a href="javascript:void(0)" *ngIf="childrenItem.RowTypeStr === '#'"
                [style.color]="childrenItem.FontColor"
                [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor">
                {{ childrenItem.MenuTag }}
              </a>
              <!-- Link -->
              <a target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
                [href]="[childrenItem.RowLink] | localize" [style.color]="childrenItem.FontColor"
                [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
                *ngIf="childrenItem.RowTypeStr != '#'">
                {{ childrenItem.MenuTag }}
              </a>
              <span class="sub-arrow mobile-back" *ngIf="menuItem.Columns.length"
                (click)="OpenRowNavActive(menuItem,childrenItem.MenuTag,childrenItem.MenuColumnRef)"></span>

            </li>
          </ul>


          <ul class="nav-submenu" [@slideInOut] style="overflow-y: auto;height: calc(100vh - 100px);"
            [class.opensubmenu]="menuItem.Ref == ActiveItem.Ref && isRowMenuOpen"
            *ngIf="RowMenuItems.length && isRowMenuOpen && !isColumnMenuOpen">
            <li class="back-btn first-back-sticky">
              <div class="row" style="margin: 0;">
                <div class="col-12" style="display: flex;align-items: center;padding: 0;">
                  <a (click)="backToColumnMenu()" style="padding: 0;">
                    <div class="mobile-back" style="padding: 10px;font-size: 15px;">
                      <img alt="" src="assets/images/icon/left-arrow.svg" style="width: 30px;margin-right: 25px;">
                      {{ActiveItemName}}
                    </div>
                  </a>
                </div>
              </div>

            </li>
            <li *ngFor="let childrenItem of RowMenuItems">
              <!-- Sub -->
              <a href="javascript:void(0)" *ngIf="childrenItem.RowTypeStr === '#'"
                [style.color]="childrenItem.FontColor"
                [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor">
                {{ childrenItem.MenuTag }}
              </a>
              <!-- Link -->
              <a target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
                [href]="[childrenItem.RowLink] | localize" [style.color]="childrenItem.FontColor"
                [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
                *ngIf="childrenItem.RowTypeStr != '#'">
                {{ childrenItem.MenuTag }}
              </a>


            </li>
          </ul>
          <ng-container *ngIf="menuItem.Columns.length">
            <div class="mega-menu-container" style="position: fixed; text-align: left;" *ngIf="menuItem.RowActive"
              [style.background]="menuItem.BackColor">
              <div class="container">
                <div class="row">
                  <div class="col mega-box" *ngFor="let childrenItems of menuItem.Columns">
                    <div style="width: 1px;height: 100%;float:right;background-color: gray;"></div>
                    <div class="link-section">
                      <div class="menu-content opensubmegamenu">
                        <ul>
                          <ng-container *ngFor="let childrenItem of childrenItems.Rows">
                            <li *ngIf="childrenItem.IsActive">
                              <div class="menu-title">
                                <h5 *ngIf="childrenItem.FontStyle == 'Bold'" [style.color]="childrenItem.FontColor"
                                  [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
                                  style="font-weight: 400;text-transform: capitalize;font-size: 14px;margin-top: 10px;width: max-content;">
                                  {{ childrenItem.MenuTag }}
                                </h5>
                              </div>
                              <!-- Sub -->
                              <a href="javascript:void(0)" [style.color]="childrenItem.FontColor"
                                [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
                                style="border: 0!important;border-radius: 0!important;font-size: 14px;font-weight: 300;text-transform: capitalize;padding: 3px 0;letter-spacing: .07em;line-height: 1.9;position: relative;"
                                *ngIf="childrenItem.RowTypeStr === '#' && childrenItem.FontStyle == 'Regular'">
                                {{ childrenItem.MenuTag }}
                              </a>
                              <a [href]="[childrenItem.RowLink] | localize"
                                target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
                                [style.color]="childrenItem.FontColor"
                                [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
                                *ngIf="childrenItem.RowTypeStr != '#' && childrenItem.FontStyle == 'Regular' && childrenItem.RowTypeStr == 'Link'">
                                {{ childrenItem.MenuTag }}
                              </a>
                              <a [href]="[childrenItem.RowLink] | localize"
                                target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
                                [style.color]="childrenItem.FontColor"
                                [style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
                                *ngIf="childrenItem.RowTypeStr != '#' && childrenItem.FontStyle == 'Regular' && childrenItem.RowTypeStr != 'Link'">
                                {{ childrenItem.MenuTag }}
                              </a>
                              <div style="float: left;" *ngIf="childrenItem.ContentTypeStr == 'Görsel'"
                                style="margin-top :10px;">
                                <a *ngIf="childrenItem.RowTypeStr != '#' && childrenItem.RowTypeStr == 'Link'"
                                  target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
                                  [href]="[childrenItem.RowLink] | localize">
                                  <img alt="MIBEBITO" style="width: 190px; border-radius: 3px; margin-right: 15px;"
                                    src="https://scm.mibebito.com/AdminService/{{childrenItem.ImageLocation}}">
                                </a>

                                <a *ngIf="childrenItem.RowTypeStr != '#' && childrenItem.RowTypeStr != 'Link'"
                                  target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
                                  [href]="[childrenItem.RowLink] | localize">
                                  <img alt="MIBEBITO" style="width: 190px; border-radius: 3px; margin-right: 15px;"
                                    src="https://scm.mibebito.com/AdminService/{{childrenItem.ImageLocation}}">
                                </a>

                                <a *ngIf="childrenItem.RowTypeStr == '#'">
                                  <img alt="MIBEBITO" style="width: 190px; border-radius: 3px; margin-right: 15px;"
                                    src="https://scm.mibebito.com/AdminService/{{childrenItem.ImageLocation}}">
                                </a>
                              </div>
                            </li>
                          </ng-container>


                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-container>


        </li>
      </ng-container>
      <ng-container *ngIf="tokenService.getUser()">
        <li class="responsive-menu-item" style="border-top: 1px solid lightgray;padding-top: 10px;margin-top: 10px;"><a
            [routerLink]="['/wishlist' | localize]" style="font-weight: 500;"> <img
              style="width: 20px;margin-right: 5px;" src="assets/images/icon/wishlistfull.svg" alt="logo">
            {{ 'menu:menu-myfavorites-title' | translate }} </a></li>
        <li class="responsive-menu-item"> <a [routerLink]="['/myorders' | localize]" style="font-weight: 500;"> <img
              style="width: 20px;margin-right: 5px;" src="assets/images/icon/order.svg" alt="logo">
            {{ 'menu:menu-myorders-title' | translate }} </a></li>
        <li class="responsive-menu-item"> <a (click)="LogOut()" style="font-weight: 500;"> <img
              style="width: 20px;margin-right: 5px;" src="assets/images/icon/signout.svg" alt="logo">
            {{ 'menu:menu-logout-title' | translate }} </a></li>
        <li style="margin-top: 10px;margin-bottom: 190px;" class="responsive-menu-item">
          <div class="row" style="background: #f5f5f5;margin: 0;">
            <div class="col-4" *ngFor="let language of languages"
              style="text-align: center;margin-bottom: 10px;margin-top: 10px;align-items: center;display: flex;justify-content: center;padding: 0;">
              <a href="javascript:void(0)" style="color: #222341;font-weight: 500;padding: 0 10px!important;"
                (click)="switchLang(language.code)" data-lng="language.code"><i
                  class="flag-icon flag-icon-{{language.flag}}"></i> {{language.name}}</a>
            </div>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="!tokenService.getUser()">
        <li style="border-top: 1px solid lightgray;padding-top: 10px;padding-bottom: 10px;margin-top: 10px;"
          class="responsive-menu-item">
          <div class="row" style="padding: 10px;margin: 0;">
            <div class="col-6" style="text-align: center;">
              <a [routerLink]="['/login' | localize]" style="font-weight: 500;color: black;"> <img
                  style="width: 20px;margin-right: 5px;" src="assets/images/icon/login.svg" alt="logo">{{
                'menu:menu-login-title' | translate }} </a>
            </div>
            <div class="col-6" style="text-align: center;">
              <a [routerLink]="['/register' | localize]" style="font-weight: 500;color: black;"> <img
                  style="width: 20px;margin-right: 5px;" src="assets/images/icon/signup.svg" alt="logo">
                {{ 'menu:menu-register-title' | translate }} </a>
            </div>
          </div>



        </li>

        <li class="responsive-menu-item" style="margin-bottom: 190px;">
          <div class="row" style="background: #f5f5f5;margin: 0;">
            <div class="col-4" *ngFor="let language of languages"
              style="text-align: center;margin-bottom: 10px;margin-top: 10px;align-items: center;display: flex;justify-content: center;padding: 0;">
              <a href="javascript:void(0)" style="color: #222341;font-weight: 500;padding: 0 10px!important;"
                (click)="switchLang(language.code)" data-lng="language.code"><i
                  class="flag-icon flag-icon-{{language.flag}}"></i> {{language.name}}</a>
            </div>
          </div>
        </li>

      </ng-container>
    </ul>
  </div>
</div>