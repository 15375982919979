import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	Ref?: number;
	ColumnCount?: number;
	FontColor?: string;
	FontStyle?: string;
	LanguageCode?: string;
	IsActive?: boolean;
	MenuName?: string;
	MenuTag?: string;
	MenuType?: number;
	MenuTypeStr?: string;
	OpenType?: string;
	Columns?: Columns[];
}
export interface Columns {
	Ref?: number;
	Rows?: Rows[];
}
export interface Rows {
	Ref?: number;
	Id?: number;
	ImageLocation?: string;
	ImageName?: string;
	BackColor?: string;
	ContentType?: number;
	ContentTypeStr?: number;
	ColumnCount?: number;
	FontColor?: string;
	FontStyle?: string;
	LanguageCode?: string;
	IsActive?: boolean;
	MenuName?: string;
	MegaMenuRef?: number;
	MenuColumnRef?: number;
	MenuTag?: string;
	RowLink?: string;
	RowType?: number;
	RowTypeStr?: string;
	StockControl?: number;
	MenuType?: number;
	MenuTypeStr?: string;
	OpenType?: string;
}
@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	// MENUITEMS: Menu[] = [
	// 	{
	// 		title: 'MEN', type: 'sub', color: 'black', megaMenu: true, active: false, children: [
	// 			{
	// 				title: 'CATEGORIES', type: 'sub', color: 'gray', active: false, children: [
	// 					{ path: '/collection', color: 'gray', title: 'View All', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Shirt', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'T-Shirt', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Sweatshirt', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Cardigan', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Sweaters', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Shorts', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Capri', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Trousers', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Jackets', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Denim', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'School Clothing', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: 'FABRIC', type: 'sub', color: 'gray', active: false, children: [
	// 					{ path: '/collection', color: 'gray', title: 'Cashmere', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Cotton', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Acrylic', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: 'HIGHLIGHTS', type: 'sub', color: 'gray',src:'', active: false, children: [
	// 					{ path: '/collection', color: '#e44747', title: 'Summer Sale', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Polo-Neck T-Shirts', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: '9" Chino Shorts ', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'NEW - Lightweight Hoodies', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: 'Best Seller', type: 'sub', color: '#2a6cbd', src:'assets/images/collection/bestseller.jpg', active: false, children: [
	// 					{ path: '/collection', color: '#493cef', title: 'Summer Trend', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Cashmere Lightweight Sweaters', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Starting from 6.00 USD', type: 'link' }
	// 				]
	// 			}
	// 		]
	// 	},
	// 	{
	// 		title: 'NEW ARRIVALS', type: 'sub', color: 'black', megaMenu: true, active: false, children: [
	// 			{
	// 				title: 'CATEGORIES', type: 'sub', color: 'gray', active: false, children: [
	// 					{ path: '/collection', color: 'gray', title: 'View All', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Under 5 USD T-Shirts', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Sweaters Keeping Warm', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Winter Cardigans', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: 'FABRIC', type: 'sub', color: 'gray', active: false, children: [
	// 					{ path: '/collection', color: 'gray', title: 'Cashmere', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Cotton', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Acrylic', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: 'HIGHLIGHTS', type: 'sub', color: 'gray', src:'assets/images/collection/megamenusale.jpg' , active: false, children: [
	// 					{ path: '/collection', color: '#e44747', title: 'Cold Weather Picks', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'New Year is Coming', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'NEW - Lightweight Hoodies', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: 'Best Seller', type: 'sub', color: '#2a6cbd', src: 'assets/images/collection/christmas.jpg', active: false, children: [
	// 					{ path: '/collection', color: '#493cef', title: 'Be quick to catch Early Christmas Sale', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Cashmere Lightweight Sweaters', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Starting from 6.00 USD', type: 'link' }
	// 				]
	// 			}
	// 		]
	// 	},
	// 	{
	// 		title: 'SALE', type: 'sub2', color: '#d03146', megaMenu: true, active: false, children: [
	// 			{
	// 				title: 'SALE', type: 'sub2', color: 'gray', active: false, children: [
	// 					{ path: '/collection', color: 'gray', title: 'View All', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: '30% Sale offer on T-Shirts', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Big Summer-end Sale up to 60%', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Early Christmas SALE!', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Two Strands of Yarn Cardigans, Catch 30% Sale', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: '', type: 'sub2', active: false, children: [
	// 					{ path: '/collection', color: 'black', src:'assets/images/collection/sale.jpg' , title: '', type: 'image' },
	// 					{ path: '/collection', color: 'black', src:'assets/images/collection/sale2.jpg' , title: '', type: 'image' }
	// 				]
	// 			}
	// 		]
	// 	},
	// 	{
	// 		title: 'TIMELESS STYLE',  color: 'black', type: 'sub2', megaMenu: true, active: false, children: [
	// 			{
	// 				title: 'TIMELESS STYLE', type: 'sub2', color: 'gray', active: false, children: [
	// 					{ path: '/collection', color: '#f9a277', title: 'Uniform Essentials', type: 'link' },
	// 					{ path: '/collection', color: 'black', title: 'Men in Black Series', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'White Classics', type: 'link' },
	// 					{ path: '/collection', color: 'gray', title: 'Half Turtle Neck Jumpers', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				title: '', type: 'sub2', active: false, children: [
	// 					{ path: '/collection', color: 'black', title: '', src:'assets/images/collection/timeless.jpg' , type: 'image' },
	// 					{ path: '/collection', color: 'black', title: '', src:'assets/images/collection/timeless2.jpg' , type: 'image' }
	// 				]
	// 			}
	// 		]
	// 	}
	// ];

	// LEFTMENUITEMS: Menu[] = [
	// 	{
	// 		title: 'clothing', type: 'sub', megaMenu: true, active: false, children: [
	// 		  {
	// 			  title: 'mens fashion',  type: 'link', active: false, children: [
	// 				  { path: '/collection', color: 'black', title: 'sports wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'top',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'bottom',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'ethic wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'sports wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'shirts',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'bottom',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'ethic wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'sports wear',  type: 'link' }
	// 			  ]
	// 		  },
	// 		  {
	// 			  title: 'women fashion',  type: 'link', active: false, children: [
	// 				  { path: '/collection', color: 'black', title: 'dresses',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'skirts',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'westarn wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'ethic wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'bottom',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'ethic wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'sports wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'sports wear',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'bottom wear',  type: 'link' }
	// 			  ]
	// 		  },
	// 		]
	// 	},
	// 	{
	// 		title: 'bags', type: 'sub', active: false, children: [
	// 		  { path: '/collection', color: 'black', title: 'shopper bags', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'laptop bags', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'clutches', type: 'link' },
	// 		  {
	// 			  path: '/collection', color: 'black', title: 'purses', type: 'link', active: false, children: [
	// 				  { path: '/collection', color: 'black', title: 'purses',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'wallets',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'leathers',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'satchels',  type: 'link' }
	// 			  ]
	// 		  },
	// 		]
	// 	},
	// 	{
	// 		title: 'footwear', type: 'sub', active: false, children: [
	// 		  { path: '/collection', color: 'black', title: 'sport shoes', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'formal shoes', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'casual shoes', type: 'link' }
	// 		]
	// 	},
	// 	{
	// 		path: '/collection', color: 'black', title: 'watches', type: 'link'
	// 	},
	// 	{
	// 		title: 'Accessories', type: 'sub', active: false, children: [
	// 		  { path: '/collection', color: 'black', title: 'fashion jewellery', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'caps and hats', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'precious jewellery', type: 'link' },
	// 		  {
	// 			  path: '/collection', color: 'black', title: 'more..', type: 'link', active: false, children: [
	// 				  { path: '/collection', color: 'black', title: 'necklaces',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'earrings',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'rings & wrist wear',  type: 'link' },
	// 				  {
	// 					  path: '/collection', color: 'black', title: 'more...',  type: 'link', active: false, children: [
	// 						  { path: '/collection', color: 'black', title: 'ties',  type: 'link' },
	// 						  { path: '/collection', color: 'black', title: 'cufflinks',  type: 'link' },
	// 						  { path: '/collection', color: 'black', title: 'pockets squares',  type: 'link' },
	// 						  { path: '/collection', color: 'black', title: 'helmets',  type: 'link' },
	// 						  { path: '/collection', color: 'black', title: 'scarves',  type: 'link' },
	// 						  {
	// 							  path: '/collection', color: 'black', title: 'more...',  type: 'link', active: false, children: [
	// 								  { path: '/collection', color: 'black', title: 'accessory gift sets',  type: 'link' },
	// 								  { path: '/collection', color: 'black', title: 'travel accessories',  type: 'link' },
	// 								  { path: '/collection', color: 'black', title: 'phone cases',  type: 'link' }
	// 							  ]
	// 						  },
	// 					]
	// 				  }
	// 			  ]
	// 		  },
	// 		]
	// 	},
	// 	{
	// 		path: '/collection', color: 'black', title: 'house of design', type: 'link'
	// 	},
	// 	{
	// 		title: 'beauty & personal care', type: 'sub', active: false, children: [
	// 		  { path: '/collection', color: 'black', title: 'makeup', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'skincare', type: 'link' },
	// 		  { path: '/collection', color: 'black', title: 'premium beaty', type: 'link' },
	// 		  {
	// 			  path: '/collection', color: 'black', title: 'more..', type: 'link', active: false, children: [
	// 				  { path: '/collection', color: 'black', title: 'fragrances',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'luxury beauty',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'hair care',  type: 'link' },
	// 				  { path: '/collection', color: 'black', title: 'tools & brushes',  type: 'link' }
	// 			  ]
	// 		  },
	// 		]
	// 	},
	// 	{
	// 		path: '/collection', color: 'black', title: 'home & decor', type: 'link'
	// 	},
	// 	{
	// 		path: '/collection', color: 'black', title: 'kitchen', type: 'link'
	// 	},
	// ];

	// Array
	// items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	// leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
