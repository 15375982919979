import { Component, Inject, Input, OnInit, PLATFORM_ID } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { ToastrService } from "ngx-toastr";
import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { TokenService } from "../../services/token.service";
import { isPlatformBrowser, ViewportScroller } from "@angular/common";

declare var $: any;
@Component({
  selector: "app-menu",
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(-100%)" }),
        animate("300ms ease-in", style({ transform: "translateX(0%)" })),
      ]),
      transition(":leave", [
        animate("300ms ease-in", style({ transform: "translateX(-100%)" })),
      ]),
    ]),
  ],
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  MenuList: Array<any> = [];
  Categories;
  MenuListByLanguage: Array<any> = [];
  @Input() stick: boolean;
  @Input() home: boolean;
  @Input() IsSearching: boolean;
  @Input() responsivethemeLogodark: string =
    "assets/images/icon/mibebito_logo_black.png"; // Default Logo
  lastscroll;

  isRowMenuOpen;
  isColumnMenuOpen;
  ColumnMenuItems: any = [];
  RowMenuItems: any = [];
  ActiveItem;
  ActiveItemRef;
  ActiveItemName;

  public languages = [
    {
      name: "English",
      code: "en",
      flag: "gb",
    },
    {
      name: "Türkçe",
      code: "tr",
      flag: "tr",
    },
    {
      name: "Russian",
      code: "ru",
      flag: "ru",
    },
  ];

  constructor(
    private router: Router,
    private viewScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: Object,
    public tokenService: TokenService,
    private http: HttpClient,
    public navServices: NavService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private localize: LocalizeRouterService
  ) {
    this.http
      .get<any>(
        environment.url +
          "Menu/GetMegaMenu?LangCode=" +
          this.translate.currentLang.toUpperCase()
      )
      .subscribe((data) => {
        this.MenuList = data.Data;
        data.Data.forEach((element: any) => {
          // element.LanguageCode == translate.currentLang.toUpperCase() &&
          if (element.IsActive) {
            element.RowActive = false;
            if (element.Columns.length) {
              element.Columns.forEach((column: any) => {
                if (column.Rows.length) {
                  column.Rows.forEach((row: any) => {
                    if (row.IsActive) element.RowActive = true;
                  });
                }
              });
            }

            if (element.Ref == 22 || element.Ref == 26 || element.Ref == 30) {
              this.Categories = element.Columns[0].Rows.filter(
                (item) => item.RowTypeStr == "Kategori"
              );
            }
            this.MenuListByLanguage.push(element);
          }
        });
      });
    // this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
      $("body").css({ overflow: "" });
      $("body").css({ position: "" });
    });
  }
  public switchLang(code) {
    this.localize.changeLanguage(code);
    this.ActiveItemName = null;
    this.ActiveItemRef = null;
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = false;
    this.ActiveItem = null;
    this.ColumnMenuItems = [];
    this.RowMenuItems = [];
  }

  getMenuFontSize() {
    try {
      if (this.translate.currentLang.toUpperCase() == "RU") return "14px";
      else return "16px";
    } catch (error) {
      return "16px";
    }
  }

  setByLanguage() {
    this.MenuListByLanguage = [];

    //
    this.MenuList.forEach((element) => {
      if (
        element.LanguageCode == this.translate.currentLang.toUpperCase() &&
        element.IsActive
      ) {
        element.RowActive = false;
        if (element.Columns.length) {
          element.Columns.forEach((column: any) => {
            if (column.Rows.length) {
              column.Rows.forEach((row: any) => {
                if (row.IsActive) element.RowActive = true;
              });
            }
          });
        }
        this.MenuListByLanguage.push(element);
      }
    });
  }

  ngOnInit(): void {
    this.localize.routerEvents.subscribe((language: string) => {
      this.http
        .get<any>(
          environment.url +
            "Menu/GetMegaMenu?LangCode=" +
            this.translate.currentLang.toUpperCase()
        )
        .subscribe((data) => {
          data.Data.forEach((element: any) => {
            //        element.LanguageCode ==  this.translate.currentLang.toUpperCase() &&
            if (element.IsActive) {
              element.RowActive = false;
              if (element.Columns.length) {
                element.Columns.forEach((column: any) => {
                  if (column.Rows.length) {
                    column.Rows.forEach((row: any) => {
                      if (row.IsActive) element.RowActive = true;
                    });
                  }
                });
              }

              if (element.Ref == 22 || element.Ref == 26 || element.Ref == 30) {
                this.Categories = element.Columns[0].Rows.filter(
                  (item) => item.RowTypeStr == "Kategori"
                );
              }
            }
          });
          this.MenuList = data.Data;
          this.setByLanguage();
        });
    });
  }
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    if (this.navServices.mainMenuToggle) {
      this.lastscroll = document.documentElement.scrollTop;
      $("body").css({ overflow: "hidden" });
      $("body").css({ position: "fixed" });
    } else {
      $("body").css({ overflow: "" });
      $("body").css({ position: "" });
      this.viewScroller.scrollToPosition([0, this.lastscroll]);
    }
  }
  // Click Toggle menu (Mobile)
  OpenColumnNavActive(item) {
    this.ActiveItem = item;
    this.ColumnMenuItems = [];

    item.Columns.forEach((element) => {
      element.Rows.forEach((row) => {
        if (row.FontStyle == "Bold" && row.IsActive) {
          this.ActiveItemRef = element.Ref;
          this.ColumnMenuItems.push(row);
        }
      });
    });
    
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = true;
  }
  OpenRowNavActive(item, name, ref) {
    this.RowMenuItems = [];
    this.ActiveItemName = name;
    item.Columns.forEach((element) => {
      if (element.Ref == ref) {
        element.Rows.forEach((row) => {
          if (row.FontStyle != "Bold" && row.IsActive)
            this.RowMenuItems.push(row);
        });
      }
    });

    this.isColumnMenuOpen = false;
    this.isRowMenuOpen = true;
  }

  backToMainMenu() {
    this.ActiveItemName = null;
    this.ActiveItemRef = null;
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = false;
    this.ColumnMenuItems = [];
    this.RowMenuItems = [];
  }
  backToColumnMenu() {
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = true;
    this.RowMenuItems = [];
  }

  LogOut() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("tokenVIP");
      localStorage.removeItem("wishlistItems");
      localStorage.removeItem("cartItems");
    }
    let translate = this.translate.translations[this.translate.currentLang];
    this.toastr.success(translate["notification:exit"]);
    this.router.navigate(["/"]);
  }
}
