import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ViewportScroller } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CMSContentComponent implements OnInit {

  @Input() content: any = {};
  data;
  isSendingMail;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  isLoading = false;
  ContactModel: any = {};
  
  WideBanner: any = {};
  constructor(private localize: LocalizeRouterService,  private route: ActivatedRoute,private http: HttpClient, private translate: TranslateService,private router: Router, private toastr: ToastrService,private viewScroller: ViewportScroller,) { 
   
  }

  ngOnInit(): void {
    this.data = this.content.Data[0];
    this.GetBanners();
    this.localize.routerEvents.subscribe((language: string) => {
      this.router.navigateByUrl('/' + language);
    });
    this.route.params.subscribe(
      params => {
        this.GetBanners();
        this.http.get<any>(environment.url + 'resolver/resolveURL?resolve=' + '/' + decodeURI(this.router.url.split('/')[2]) + '&langCode=' + this.translate.currentLang).subscribe(data => {
          this.data = data.content.Data[0];
        });
      });
  }
  ngAfterContentChecked(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    $('p').css('font-size','14');
    $('p').css('font-weight','500');
    $('p').css('color','black');
    $('p').css('line-height','21px');

  }
  GetBanners() {
    this.WideBanner = {};
    this.http.get<any>(environment.url + 'Banner/GetBanners').subscribe(data => {
      this.WideBanner = data.find(x => x.BannerTypeRef == 8 && '/' + this.translate.currentLang + x.RowLink == this.router.url && x.LangCode === this.translate.currentLang.toUpperCase());
    });
  }

  gotoContact(){
    var contactPosition = document.getElementById("contact-form").offsetTop - 150;
    
    this.viewScroller.scrollToPosition([0, contactPosition]);

  }

  SendMail(value: any){
    this.isSendingMail = true;
    this.http.post<any>(environment.url + 'Request/Contact?LanguageCode=' + this.translate.currentLang + '&Email=' + this.ContactModel.Email + '&FirstName=' + this.ContactModel.FirstName+ '&LastName=' + this.ContactModel.LastName+ '&PhoneNumber=' + this.ContactModel.PhoneNumber.internationalNumber+ '&Subject=' + this.ContactModel.Subject+ '&Message=' + this.ContactModel.Message, null).subscribe(data => {
      if (data.Success) {
        this.isSendingMail = false;
        this.ContactModel = {};
        value.resetForm();
        let translate = this.translate.translations[this.translate.currentLang];
        this.toastr.success(translate['contact:form-sended']);
      }
      else {
        this.isSendingMail = false;
        let translate = this.translate.translations[this.translate.currentLang];
        this.toastr.error(translate['contact-error:form-sended']);
      }
    },
      error => {
        this.isSendingMail = false;
        let translate = this.translate.translations[this.translate.currentLang];
        this.toastr.error(translate['contact-error:form-sended']);
      });
  }
}
