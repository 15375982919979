<!-- breadcrumb start -->
<div class="breadcrumb-section" style="background-color: white;">
  <div class="container">
     <div class="row">
      <!-- <div class="col-sm-6">
        <div class="page-title">
          <h2>{{ title }}</h2>
        </div>
      </div> --> 
      <div class="col-sm-12 padding-50">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb" style="justify-content: flex-start;padding: 0px;">
            <li class="breadcrumb-item"><a [routerLink]="'/' | localize">{{'breadcrumb:home-title' | translate}}</a></li>
            <li class="breadcrumb-item" aria-current="page" *ngIf="!category">{{ breadcrumb }}</li>
            <li class="breadcrumb-item" aria-current="page" *ngIf="category"><a [routerLink]="link | localize">{{ category }}</a></li>
            <li class="breadcrumb-item active" aria-current="page" *ngIf="title">{{ title }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->