import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 

  public languages = [{
    name: 'English',
    code: 'en',
    flag: 'gb'
  }, {
    name: 'Türkçe',
    code: 'tr',
    flag: 'tr'
  }, {
    name: 'Russian',
    code: 'ru',
    flag: 'ru'
  }];
  public today: number = Date.now();

  MenuList: Array<any> = [];
  FooterMenu;
  LoginModel: any = {};
  MenuListByLanguage: Array<any> = [];
  policies;

  constructor(private http: HttpClient,
    private translate: TranslateService, private localize: LocalizeRouterService) {
    this.http.get<any>(environment.url + 'Menu/GetFooterMenuByLang?langCode=' + this.translate.currentLang).subscribe(data => {
      this.FooterMenu = data.Data[0];
      this.MenuList = data.Data[0].Columns;
    });
    this.localize.routerEvents.subscribe((language: string) => {
      this.http.get<any>(environment.url + 'Menu/GetFooterMenuByLang?langCode=' + this.translate.currentLang).subscribe(data => {
        this.FooterMenu = data.Data[0];
        this.MenuList = data.Data[0].Columns;
      });
      this.GetPolicies();
    });
  }

  ngOnInit(): void {
    this.GetPolicies();

  }
  public switchLang(code) {
    this.localize.changeLanguage(code);
  }
  Newsteller(){}
  GetPolicies() {
    this.http.get<any>(environment.url + 'Policies/GetPoliciesPages?&LanguageCode=' + this.translate.currentLang).subscribe(data => {
      this.policies = data.Data;
    });
  }
}
