import {
  Component, OnInit, PLATFORM_ID, Inject
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-accept-cookies',
  templateUrl: './accept-cookies.component.html',
  styleUrls: ['./accept-cookies.component.scss']
})
export class AcceptCookiesComponent implements OnInit {
  IsCookieAccept;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("accept-cookies") == 'true') this.IsCookieAccept = true;
    }
  }
  CloseTheCookies(){
    this.IsCookieAccept = true;
    localStorage.setItem("accept-cookies", 'true');
  }
}