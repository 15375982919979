<div *ngIf="!loader && isMobile">
  <div class="img-wrapper">
    <div class="lable-block" style="position: absolute;top: 5px;left: 6px;z-index: 1;">
      <span class="new-product" *ngIf="product.IsNewProduct"> {{'product:new-title' | translate}}</span>
     
      <div style="margin-bottom: 5px;margin-top: 5px;"
        *ngIf="ProductVariantCode.Balance != 0 && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice">
        <span class="sale">{{'product:product-sale-text'
          | translate}}</span>
        <span class="sale-percent">
          <img alt="" src="assets/images/icon/two-down-arrows.svg"
            style="width: 12px;;margin-right: 5px;filter: invert(1);">{{ ((product?.OldSalesPrice /
          product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%</span>
      </div>
      <span class="no-stock" *ngIf="ProductVariantCode.Balance === 0">{{'product:no-stock' | translate}}</span>
    </div>
    <span class="wishlist">
      <a href="javascript:void(0)" *ngIf="!Wishlist()" style="vertical-align: middle;color: black;"
        title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="addToWishlist(product)">
        <img src="assets/images/nullwishlist.svg" style="width: 20px;height: 20px;">
      </a>
      <a href="javascript:void(0)" *ngIf="Wishlist()" style="color: black;"
        title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="removeToWishlist(product)">
        <img src="assets/images/wishlist.svg" style="width: 20px;height: 20px;">
      </a>

    </span>
    <div class="front">
      <a *ngIf="product.StockVariants.length && product.StockVariants[0].Images.length" style="display: grid;"
        [routerLink]="[routerurl+ product?.URL + '-' + VariantCode | localize]">
        <img [lazyLoad]="ImageSrc ? ImageSrc : product.StockVariants[0].Images[0].ImageLocation"
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
       
          class="img-fluid lazy-loading" [ngClass]="{'active-image1': !isMobile}" />

        <img [lazyLoad]="ImageSrc2 ? ImageSrc2 : product.StockVariants[0].Images[1].ImageLocation" *ngIf="!isMobile"
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          class="img-fluid lazy-loading" [ngClass]="{'active-image2': !isMobile}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a *ngIf="product.StockVariants" [routerLink]="[routerurl+ product?.URL + '-' + VariantCode | localize]">
        <img [lazyLoad]="ImageSrc ? ImageSrc : product.StockVariants[0].Images[0].ImageLocation"
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          class="img-fluid lazy-loading">
      </a>
    </div>

    <div class="cart-info cart-wrap product-size product-size-responsive-none"
      style="display: flex;align-items: center;">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye" style="font-size: 19px;font-weight: 400;color: black;"></i>
        </a>
      </div>

      <div class="size-box"
        style="background-color: #E8E8EF;display: -webkit-inline-box;width: fit-content;margin: auto;padding: 3px;">
        <div *ngFor="let size of Size(product.ItemSizes)">
          <span class="sizename">
            {{ size.SizeName | titlecase }}
          </span>
          <span class="product-size-amount">{{size.SizeAmount}}</span>
        </div>
      </div>
      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart" style="font-size: 19px;font-weight: 400;color: black;"></i>
      </a>
    </div>
    <div class="cart-info cart-wrap product-size product-size-responsive-block" *ngIf="!gridFlex"
      style="display: flex;align-items: center;" id="cart-info">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye"
            style="font-size: 15px;font-weight: 400;color: black;padding-right: 5px;padding-left: 5px;"></i>
        </a>
      </div>
      <div class="size-box"
        style="background-color: #E8E8EF;display: -webkit-inline-box;width: fit-content;margin: auto;padding: 3px;">
        <div *ngFor="let size of Size(product.ItemSizes)">
          <span class="sizename">
            {{ size.SizeName | titlecase }}
          </span>
          <span class="product-size-amount">{{size.SizeAmount}}</span>
        </div>
      </div>
      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart"
          style="font-size: 15px;font-weight: 400;color: black;padding-right: 5px;padding-left: 5px;"></i>
      </a>


    </div>
    <div class="cart-info cart-wrap product-size product-size-responsive-flex" *ngIf="gridFlex"
      style="display: flex;align-items: center;" id="cart-info">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye" style="font-size: 19px;font-weight: 400;color: black;"></i>
        </a>
      </div>

      <div class="size-box"
        style="background-color: #E8E8EF;display: -webkit-inline-box;width: fit-content;margin: auto;padding: 3px;">
        <div *ngFor="let size of Size(product.ItemSizes)">
          <span class="sizename-gridflex">
            {{ size.SizeName | titlecase }}
          </span>
          <span class="product-size-amount-gridflex">{{size.SizeAmount}}</span>
        </div>
      </div>
      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart" style="font-size: 19px;font-weight: 400;color: black;"></i>
      </a>
    </div>
  </div>
  <div class="product-detail" style="margin-bottom: 20px;">
    <div>
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
      <a [routerLink]="[routerurl + product?.URL + '-'  + product?.StockVariants[0]?.VariantCode | localize]">
        <h6>{{ product?.Name | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <ng-container *ngIf="ProductVariantCode.Balance === 0">
        <h4 *ngIf="tokenService.getUser()"> ${{ product?.OldSalesPrice > product?.CardStoreSalesPrice ?
          (product?.OldSalesPrice | currency: ' ':'symbol':'.2-2':'it') : product?.CardStoreSalesPrice | currency: '
          ':'symbol':'.2-2':'it' }}</h4>
      </ng-container>
      <ng-container *ngIf="ProductVariantCode.Balance !== 0">
        <h4
          *ngIf="tokenService.getUser() && product?.CardStoreSalesPrice == product?.OldSalesPrice || product?.CardStoreSalesPrice > product?.OldSalesPrice">
          ${{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-2':'it' }}</h4>
        <h4
          *ngIf="tokenService.getUser() && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
          style="color: #A83151;font-size: 16px;">
          <del style="font-size: 14px;color: #aaa;"><span class="money">${{ product?.OldSalesPrice | currency: '
              ':'symbol':'.2-2':'it' }}</span></del>
          ${{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-2':'it' }} (-{{ ((product?.OldSalesPrice /
          product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%)
        </h4>
      </ng-container>

      <a *ngIf="!tokenService.getUser()" [ngClass]="{'only-member-gridflex': !gridFlex}" class="only-member"
        [routerLink]="[routerurl + product?.URL + '-'  + product?.StockVariants[0]?.VariantCode | localize]">
        {{'product:product-only-member' | translate}}
      </a>
      <span *ngIf=" product.StockVariants.length > 1" class="color-available"
        [ngClass]="{'color-available-gridflex': !gridFlex}">{{ product.StockVariants.length}}
        {{'product:product-colors-available' | translate}}</span>
      <span *ngIf=" product.StockVariants.length == 1" class="color-available" style="margin-bottom: 20px;">{{
        product.StockVariants[0].ColorName}}</span>
    </div>
  </div>
</div>
<div *ngIf="!loader && !isMobile">
  <div class="img-wrapper">
    <div class="lable-block" style="position: absolute;top: 5px;left: 6px;z-index: 1;">
      <span class="new-product" *ngIf="product.IsNewProduct">{{'product:new-title' | translate}}</span>
      <div style="margin-bottom: 5px;margin-top: 5px;"
        *ngIf="ProductVariantCode.Balance != 0 && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice">
        <span class="sale">{{'product:product-sale-text'
          | translate}}</span>
        <span class="sale-percent">
          <img alt="" src="assets/images/icon/two-down-arrows.svg"
            style="width: 12px;;margin-right: 5px;filter: invert(1);">{{ ((product?.OldSalesPrice /
          product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%</span>
      </div>
      <span class="no-stock" *ngIf="ProductVariantCode.Balance === 0">{{'product:no-stock' | translate}}</span>
    </div>
    <span class="wishlist">
      <a href="javascript:void(0)" *ngIf="!Wishlist()" style="vertical-align: middle;color: black;"
        title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="addToWishlist(product)">
        <img src="assets/images/nullwishlist.svg" style="width: 20px;height: 20px;">
      </a>
      <a href="javascript:void(0)" *ngIf="Wishlist()" style="color: black;"
        title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="removeToWishlist(product)">
        <img src="assets/images/wishlist.svg" style="width: 20px;height: 20px;">
      </a>

    </span>
    <div class="front">
    

      <a *ngIf="product.StockVariants.length && product.StockVariants[0].Images.length" style="display: grid;"
        [routerLink]="[routerurl+ product?.URL + '-' + VariantCode | localize]">
        <img [lazyLoad]="ImageSrc ? ImageSrc : product.StockVariants[0].Images[0].ImageLocation"
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
        [attr.alt]="product?.Name" 
          class="img-fluid lazy-loading" [ngClass]="{'active-image1': !isMobile}" />

        <img [lazyLoad]="ImageSrc2 ? ImageSrc2 : product.StockVariants[0].Images[1].ImageLocation" *ngIf="!isMobile"
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
        [attr.alt]="product?.Name" 
          class="img-fluid lazy-loading" [ngClass]="{'active-image2': !isMobile}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a *ngIf="product.StockVariants" [routerLink]="[routerurl+ product?.URL + '-' + VariantCode | localize]">
        <img [lazyLoad]="ImageSrc ? ImageSrc : product.StockVariants[0].Images[0].ImageLocation"
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
        [attr.alt]="product?.Name" 
          class="img-fluid lazy-loading">
      </a>
    </div>

    <div class="cart-info cart-wrap product-size product-size-responsive-none"
      style="display: flex;align-items: center;">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye" style="font-size: 19px;font-weight: 400;color: black;"></i>
        </a>
      </div>

      <div class="size-box"
        style="background-color: #E8E8EF;display: -webkit-inline-box;width: fit-content;margin: auto;padding: 3px;">
        <div *ngFor="let size of Size(product.ItemSizes)">
          <span class="sizename">
            {{ size.SizeName | titlecase }}
          </span>
          <span class="product-size-amount">{{size.SizeAmount}}</span>
        </div>
      </div>
      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart" style="font-size: 19px;font-weight: 400;color: black;"></i>
      </a>
    </div>
    <div class="cart-info cart-wrap product-size product-size-responsive-block" *ngIf="!gridFlex"
      style="display: flex;align-items: center;" id="cart-info">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye"
            style="font-size: 15px;font-weight: 400;color: black;padding-right: 5px;padding-left: 5px;"></i>
        </a>
      </div>
      <div class="size-box"
        style="background-color: #E8E8EF;display: -webkit-inline-box;width: fit-content;margin: auto;padding: 3px;">
        <div *ngFor="let size of Size(product.ItemSizes)">
          <span class="sizename">
            {{ size.SizeName | titlecase }}
          </span>
          <span class="product-size-amount">{{size.SizeAmount}}</span>
        </div>
      </div>
      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart"
          style="font-size: 15px;font-weight: 400;color: black;padding-right: 5px;padding-left: 5px;"></i>
      </a>


    </div>
    <div class="cart-info cart-wrap product-size product-size-responsive-flex" *ngIf="gridFlex"
      style="display: flex;align-items: center;" id="cart-info">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye" style="font-size: 19px;font-weight: 400;color: black;"></i>
        </a>
      </div>

      <div class="size-box"
        style="background-color: #E8E8EF;display: -webkit-inline-box;width: fit-content;margin: auto;padding: 3px;">
        <div *ngFor="let size of Size(product.ItemSizes)">
          <span class="sizename-gridflex">
            {{ size.SizeName | titlecase }}
          </span>
          <span class="product-size-amount-gridflex">{{size.SizeAmount}}</span>
        </div>
      </div>
      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart" style="font-size: 19px;font-weight: 400;color: black;"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
      <a [routerLink]="[routerurl + product?.URL + '-'  + product?.StockVariants[0]?.VariantCode | localize]">
        <h6>{{ product?.Name | titlecase }}</h6>
      </a>
      <p>{{ product?.description }}</p>
      <ng-container *ngIf="ProductVariantCode.Balance === 0">
        <h4 *ngIf="tokenService.getUser()"> ${{ product?.OldSalesPrice > product?.CardStoreSalesPrice ?
          (product?.OldSalesPrice | currency: ' ':'symbol':'.2-2':'it') : product?.CardStoreSalesPrice | currency: '
          ':'symbol':'.2-2':'it' }}</h4>
      </ng-container>
      <ng-container *ngIf="ProductVariantCode.Balance !== 0">
        <h4
          *ngIf="tokenService.getUser() && product?.CardStoreSalesPrice == product?.OldSalesPrice || product?.CardStoreSalesPrice > product?.OldSalesPrice">
          ${{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-2':'it' }}</h4>
        <h4
          *ngIf="tokenService.getUser() && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
          style="color: #A83151;font-size: 16px;">
          <del style="font-size: 14px;color: #aaa;"><span class="money">${{ product?.OldSalesPrice | currency: '
              ':'symbol':'.2-2':'it' }}</span></del>
          ${{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-2':'it' }} (-{{ ((product?.OldSalesPrice /
          product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%)
        </h4>
      </ng-container>

      <a *ngIf="!tokenService.getUser()" [ngClass]="{'only-member-gridflex': !gridFlex}" class="only-member"
        [routerLink]="[routerurl + product?.URL + '-'  + product?.StockVariants[0]?.VariantCode | localize]">
        {{'product:product-only-member' | translate}}
      </a>
      <span *ngIf=" product.StockVariants.length > 1" class="color-available"
        [ngClass]="{'color-available-gridflex': !gridFlex}">{{ product.StockVariants.length}}
        {{'product:product-colors-available' | translate}}</span>
      <span *ngIf=" product.StockVariants.length == 1" class="color-available">{{
        product.StockVariants[0].ColorName}}</span>
      <ul class="color-variant" id="color-variant{{product?.Code}}" *ngIf="Color(product?.StockVariants).length">
        <li *ngFor="let color of Color(product?.StockVariants);let first = first;"
          [ngClass]="{'color-variant-active': first}" style="margin-right: 5px;margin-bottom: 5px;padding: 3px;">
          <a class="color-variant-color" (click)="ChangeVariants(color.ColorName, product,$event,product?.Code)"
            [ngStyle]="{'background-color': color.HexCode}">
            <button class="color-variant-button">{{color.ColorName}}</button>
          </a>
        </li>
      </ul>

    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [Item]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>