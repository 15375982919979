<!--footer section -->
<footer [class]="class" *ngIf="FooterMenu" [style.background]="FooterMenu.BackColor" style="background-color: #f1f1f1;">

	<section style="padding: 0;padding-bottom: 20px;border-bottom: 1px solid #e0dbdb;background-color: white;">
		<div class="container padding-50">
			<div class="row footer-theme partition-f">
				<div class="col" *ngFor="let menuItem of MenuList">
					<div class="sub-title">
						<div class="footer-contant">

							<ul>
								<li *ngFor="let childrenItem of menuItem.Rows">
									<div class="menu-title">
										<h5 *ngIf="childrenItem.FontStyle == 'Bold'"
											[style.color]="childrenItem.FontColor"
											[style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
											style="font-weight: 500;text-transform: capitalize;font-size: 14px;margin-top: 10px;">
											{{ childrenItem.MenuTag }}
										</h5>
									</div>
									<!-- Sub -->
									<a href="javascript:void(0)" [style.color]="childrenItem.FontColor"
										[style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
										style="border: 0!important;border-radius: 0!important;font-size: 14px;font-weight: 400;text-transform: capitalize;padding: 3px 0;letter-spacing: .07em;line-height: 1.9;position: relative;"
										*ngIf="childrenItem.RowTypeStr === '#' && childrenItem.FontStyle == 'Regular'">
										{{ childrenItem.MenuTag }}
									</a>
									<a [href]="childrenItem.RowLink | localize"
										target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
										[style.color]="childrenItem.FontColor"
										[style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
										*ngIf="childrenItem.RowTypeStr != '#' && childrenItem.FontStyle == 'Regular' && childrenItem.RowTypeStr == 'Link'">
										{{ childrenItem.MenuTag }}
									</a>
									<a [routerLink]="['/' + childrenItem.RowLink | localize]"
										target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
										[style.color]="childrenItem.FontColor"
										[style.background]="childrenItem.BackColor == '#FFFFFF' ? 'transparent' : childrenItem.BackColor"
										*ngIf="childrenItem.RowTypeStr != '#' && childrenItem.FontStyle == 'Regular' && childrenItem.RowTypeStr != 'Link'">
										{{ childrenItem.MenuTag }}
									</a>
									<div style="float: left;" *ngIf="childrenItem.ContentTypeStr == 'Görsel'"
										style="margin-top :10px;">
										<a *ngIf="childrenItem.RowTypeStr != '#' && childrenItem.RowTypeStr == 'Link'"
											target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
											[href]="childrenItem.RowLink | localize">
											<img alt="MIBEBITO"
												style="width: 190px; border-radius: 3px; margin-right: 15px;"
												src="https://scm.mibebito.com/AdminService/{{childrenItem.ImageLocation}}">
										</a>

										<a *ngIf="childrenItem.RowTypeStr != '#' && childrenItem.RowTypeStr != 'Link'"
											target="{{childrenItem.OpenType == '_blank' ? '_blank' : '_self'}}"
											[routerLink]="'/' + childrenItem.RowLink | localize">
											<img alt="MIBEBITO"
												style="width: 190px; border-radius: 3px; margin-right: 15px;"
												src="https://scm.mibebito.com/AdminService/{{childrenItem.ImageLocation}}">
										</a>

										<a *ngIf="childrenItem.RowTypeStr == '#'">
											<img alt="MIBEBITO"
												style="width: 190px; border-radius: 3px; margin-right: 15px;"
												src="https://scm.mibebito.com/AdminService/{{childrenItem.ImageLocation}}">
										</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>


			</div>
		</div>
	</section>
	<div class="payment-card-bottom" style="padding: 15px 0 0 0;">
		<div class="row" style="margin: 0;">
			<div class="col-lg-6 responsive-social-icons" style="justify-content: flex-end;text-align: center;display: flex;">
				<div class="footer-social" style="margin-top: 0;">
					<ul>
						<li>
							<a href="https://www.facebook.com/mibebito.official" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
						</li>
						<li>
							<a href="https://www.instagram.com/vip_stendo/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
						</li>
						<li>
							<a href="https://www.youtube.com/channel/UCrYbOsTiOHgspSxZJxoYFfA" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a>
						</li>
						<li>
							<a href="https://www.tiktok.com/@mibebito"  target="_blank"><i class="fa fa-tiktok" aria-hidden="true"></i></a>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-6 responsive-cart-icons" style="justify-content: start;text-align: center;display: flex;">
				<ul>
					<li style="padding : 7px;display: inline-block;">
						<img src="assets/images/icon/vs_visa.png" style="width: 60px;" class="responsive-payment-icons" alt="">
					</li>
					<li style="padding : 7px;display: inline-block;">
						<img src="assets/images/icon/vs_master.png" style="width: 60px;" class="responsive-payment-icons" alt="">
					</li>
					<li style="padding : 7px;display: inline-block;">
						<img src="assets/images/icon/vs_western.png" style="width: 60px;" class="responsive-payment-icons" alt="">
					</li>
					<li style="padding : 7px;display: inline-block;">
						<img src="assets/images/icon/vs_swift.png" style="width: 60px;" class="responsive-payment-icons" alt="">
					</li>
				</ul>
			</div>
		</div>
		
	
	
	</div>
	<section class="section-b-space">
		<div class="container padding-50" style="text-align: center;">
			<div class="row information-tabs" style="padding: 10px 10px;">
				<div class="col-lg-9 border-right-line" style="display: flex;align-items: center;text-align: left;">
					<div class="row">
						<div class="col-lg-12">
							<div class="row">
								<div class="col-lg-4 margin-bottom-3" style="align-items: center;display: flex;justify-content: center;">
									<div class="row" style="align-items: center;">

										<div class="row" style="margin: 0;align-items: center;justify-content: center;">
											<div class="col-4 margin-bottom-social"
												style="padding: 0;text-align: center;">
												<h3
													style="color: black;font-weight: 600;font-size: 13px;margin-bottom: 0;">
													{{'home:home-whatsapp-text' | translate}}</h3>
											</div>
											<div class="col-6 col-lg-8" style="padding: 0;">
											</div>
											<div class="col-4"
												style="display: flex;justify-content: center;max-height: max-content;">
												<img src="assets/images/icon/whatsapp.svg" class="responsive-footer-icons"
													style="width: 80px;max-height: 80px;filter: invert(40%) sepia(77%) saturate(1574%) hue-rotate(85deg) brightness(92%) contrast(80%);"
													alt="">
											</div>
											<div class="col-6 col-lg-8 text-align padding-0">
												<a style="color: black;" target="_blank"
												href="https://wa.me/{{'home:home-call-center-phone1-link' | translate}}?text={{'whatsapp-chat-message' | translate}}">
													<h4 class="phone-number-text">
														{{'home:home-call-center-phone1' | translate}}</h4>
												</a>
												<a style="color: black;" target="_blank"
												href="https://wa.me/{{'home:home-call-center-phone2-link' | translate}}?text={{'whatsapp-chat-message' | translate}}">
													<h4
														class="phone-number-text">
														{{'home:home-call-center-phone2' | translate}}</h4>
												</a>

											</div>
										</div>

									</div>
								</div>
								<div class="col-lg-4 margin-bottom-3" style="align-items: center;display: flex;justify-content: center;">
									<div class="row" style="align-items: center;">

										<div class="row" style="margin: 0;align-items: center;justify-content: center;">
											<div class="col-4 margin-bottom-social"
												style="padding: 0;text-align: center;">
												<h3
													style="color: black;font-weight: 600;font-size: 13px;margin-bottom: 0;">
													{{'home:home-telegram-text' | translate}}</h3>
											</div>
											<div class="col-6 col-lg-8" style="padding: 0;">
											</div>
											<div class="col-4"
												style="display: flex;justify-content: center;max-height: max-content;">
												<img src="assets/images/icon/telegram.svg" class="responsive-footer-icons"
													style="width: 80px;max-height: 80px;filter: invert(40%) sepia(77%) saturate(1574%) hue-rotate(85deg) brightness(92%) contrast(80%);"
													alt="">
											</div>
											<div class="col-6 col-lg-8 text-align padding-0">
												<a style="color: black;" target="_blank"
													href="https://telegram.me/mibebito">
													<h4 class="phone-number-text">
														{{'home:home-call-center-phone1' | translate}}</h4>
												</a>

												<a style="color: black;" target="_blank"
													href="https://telegram.me/mibebito">
													<h4 class="phone-number-text">
														{{'home:home-call-center-phone2' | translate}}</h4>
												</a>
											</div>
										</div>

									</div>
								</div>
								<div class="col-lg-4 margin-bottom-3" style="align-items: center;display: flex;justify-content: center;">
									<div class="row" style="align-items: center;">

										<div class="row" style="margin: 0;align-items: center;justify-content: center;">
											<div class="col-4 margin-bottom-social"
												style="padding: 0;text-align: center;">
												<h3
													style="color: black;font-weight: 600;font-size: 13px;margin-bottom: 0;">
													{{'home:home-call-center-text' | translate}}</h3>
											</div>
											<div class="col-6 col-lg-8" style="padding: 0;">
											</div>
											<div class="col-4"
												style="display: flex;justify-content: center;max-height: max-content;">
												<img src="assets/images/icon/headphone.svg" class="responsive-footer-icons"
													style="width: 80px;max-height: 80px;filter: invert(40%) sepia(77%) saturate(1574%) hue-rotate(85deg) brightness(92%) contrast(80%);"
													alt="">
											</div>
											<div class="col-6 col-lg-8 text-align padding-0">
												<a style="color: black;" target="_blank"
													href="tel:{{'home:home-call-center-phone1' | translate}}">
													<h4 class="phone-number-text">
														{{'home:home-call-center-phone1' | translate}}</h4>
												</a>
												<a style="color: black;" target="_blank"
													href="tel:{{'home:home-call-center-phone2' | translate}}">
													<h4
														class="phone-number-text">
														{{'home:home-call-center-phone2' | translate}}</h4>
												</a>

											</div>
										</div>

									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
				<div class="col-lg-3" style="border: 2px dashed #229c19;padding: 10px;">
					<div style="text-align: center;">
						<h3 style="color: black;font-weight: 600;font-size: 15px;">
							{{'home:home-call-center-weekdays-title'
							| translate}}</h3>
						<span style="font-size: 12px;">{{'home:home-call-center-weekdays-text' | translate}}</span>
					</div>
					<hr style="width: 60%;margin-top: 10px;margin-bottom: 10px;" />
					<div style="text-align: center;">
						<h3 style="color: black;font-size: 15px;font-weight: 600;">
							{{'home:home-call-center-weekends-title'
							| translate}}</h3>
						<span style="font-size: 12px;">{{'home:home-call-center-weekends-text' | translate}}</span>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section-b-space">
		<div class="container padding-50">
			<div class="footer-brand-tab" style="text-align: center;">
				<span class="visit-our-text">
					{{'home:home-visit-our-websites' | translate}} <span class="responsive-block"> : </span>
				</span>
				<div class="row footer-brand-row">
					<div class="col-lg-4 footer-brand-col"
						style="font-weight: 500;background-color: #004a80;align-items: center;display: flex;justify-content: center;">
						<a href="{{'home:home-visit-our-website-1-link' | translate}}" target="blank_"
							style="color: white;">
							{{'home:home-visit-our-website-1' | translate}}
						</a>
					</div>
					<div class="col-lg-4 footer-brand-col"
						style="align-items: center;display: flex;justify-content: center;">
						<a href="{{'home:home-visit-our-website-2-link' | translate}}" target="blank_"
							style="color: #212529;">
							<span style="font-weight: 800;">{{'home:home-visit-our-website-2' |
								translate}}</span>{{'home:home-visit-our-website-2.1' | translate}}
						</a>
					</div>
					<div class="col-lg-4 footer-brand-col"
						style="align-items: center;display: flex;justify-content: center;">
						<a href="{{'home:home-visit-our-website-3-link' | translate}}" target="blank_"
							style="color: #212529;">
							<span style="font-weight: 800;">{{'home:home-visit-our-website-3' |
								translate}}</span>{{'home:home-visit-our-website-3.1' | translate}}

						</a>
					</div>
				</div>
				<a href="{{'home:home-visit-our-website-mibebito-link' | translate}}" target="blank_"
					style="color: red;" class="margin-left-30 mibebito-responsive">
					<img src="assets/images/icon/mibebito.png" class="mibebito-img" style="width: 125px;" alt="">
				</a>
			</div>
		</div>

	</section>
	<section class="section-b-space mt-3 mb-3 responsive-language">
		<div class="container padding-50">
			<ul style="text-align: center;">
				<li *ngFor="let language of languages" style="margin-right: 20px;margin-bottom: 10px;"><a
						href="javascript:void(0)" style="color: #222341;font-weight: 500;"
						(click)="switchLang(language.code)" data-lng="language.code"><i
							class="flag-icon flag-icon-{{language.flag}}"></i> {{language.name}}</a></li>
			</ul>
		</div>

	</section>


	<div class="sub-footer" style="margin-top: 10px;padding-bottom: 15px;">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 col-md-12 col-sm-12" style="padding: 0 15px;">
					<div class="footer-end" style="text-align: center;">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> Copyright {{'footer:footer-copyright-year'
							| translate}} <a href="http://nacikarabulut.com/" target="blank_"
								style="color: black;margin-bottom: 0;font-size: 14px;font-weight: 600;display: -webkit-inline-box;">Naci
								Karabulut</a> -
							{{'footer:all-rights-reserved' | translate}}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->